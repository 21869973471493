import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekEllipse = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekEllipse {
        products: allStrapiProduct(
          filter: { brand: { eq: "Patek Philippe" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Ellipse Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/patek-philippe-ellipse/'}
      description={
        ' Grays & Sons are experts in buying and selling second hand Patek Philippe Ellipse Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/model/ellipse/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-ellipse-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Ellipse Watches Header"
                aria-label="Used Patek Ellipse Calendar Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE ELLIPSE
              WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE ELLIPSE WATCHES WITH
              GRAY AND SONS JEWELERS
            </h2>
            <p>
              We are a real watch and jewelry store located in Miami. We are across from Bal Harbour
              Shops, with real watch and jewelry experts on-site. We're open six days a week and not
              a virtual store, however we also have a website. Our customers say we are the #1 place
              in Miami to buy used watches. We're proudly assisting customers for the past 42 years.
              We're sellers and buyers of pre-owned Patek Philippe Ellipse watches in Miami, Miami
              Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles ,
              Aventura, Fort Lauderdale and more areas.
              <br />
              <br />
              Thinking of buying or selling a Patek Philippe Ellipse? If so, Gray and Sons at{' '}
              <a href="/">www.grayandsons.com </a> is the best place to go for Patek Philippe
              watches. Would you like to &ensp;
              <a href="/chat-with-viktoria">MAKE A DEAL</a> on watches and jewelry? Vika and Rich
              are live expert decision makers and ready to work with you now. Introduced in 1968,
              the Patek Philippe Ellipse watch was designed using the golden ratio principle, and
              its shape, which combines a circle and a rectangle, broke away from traditional dress
              watches of the era. Characterized by slim yet curvaceous gold cases, vibrant blue gold
              dials, and intricately crafted gold bracelets, the Patek Golden Ellipse was an instant
              hit. More than five decades later, the Golden Ellipse is still an integral part of the
              Patek Philippe watch catalog and a classic choice for fans of simple design.
              <br />
              <br />
              As one of the longest-running references in the brand’s history, the choice of
              pre-owned Patek Philippe Ellipse watch references is abundant. Patek Philippe even
              made some spin-offs of the popular Ellipse watch, including the Golden Circle, which
              is a cushion-shaped variant. If you’re in the market to buy a certified pre-owned
              Patek Ellipse timepiece, here are a few things to keep in mind:
              <ul>
                <li>-There are time-only and time and date used Patek Ellipse watches</li>
                <li>-There are secondhand Patek Ellipse watches for men and women</li>
                <li>
                  -Vintage men’s Patek Ellipse watches are available in a variety of sizes,
                  generally ranging from 27mm to 38mm (in height)
                </li>
                <li>-There are manual, automatic, and quartz pre-owned Patek Ellipse watches </li>
              </ul>
              <br />
              <br />
              Gray and Sons has a large in-stock inventory of secondhand Patek Philippe Ellipse
              watches, with additional models added regularly. As an independent Patek Philippe
              watch dealer, we proudly offer price flexibility (and inventory control) so make a
              deal with one of our decision-makers when you’re ready, offer 
              certfied pre-owned watches.
              <br />
              <br />
              Alternatively, if you’ve recently thought to yourself, I want to sell my Patek
              Philippe Ellipse watch for cash, contact Gray and Sons (or visit &ensp;
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com)</a> for the best
              cash offer in the market.
              <br />
              <br />
              Popular Pre-Owned Patek Philippe Golden Ellipse are Reference 3546, Reference 3548,
              Reference 3589, Reference 3605, Reference 3610, Reference 3609, Reference 3617,
              Reference 3620 and Reference 3738. Gray and Sons is a full-service Patek Philippe
              Ellipse watch repair shop. Due to their fine craftsmanship and intricate movements,
              the repair or servicing of a used Patek Golden Ellipse watch must only be trusted to
              expert watchmakers. Gray and Sons is an independent Patek Philippe repair service
              center (we also offer battery replacement for Patek Ellipse watches), and our
              customers agree that we are the best full-service watch and jewelry store in Miami.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/model/ellipse/">
                <button>SHOP PATEK PHILIPPE ELLIPSE WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekEllipse
